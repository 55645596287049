<template>
  <div class="">
    <form @submit.prevent="formSend">
      <label>信用卡交易資訊</label>
      <div class="form-group">
        <label for="exampleInputName">姓名*</label>
        <input type="text" class="form-control" id="exampleInputName" placeholder="Name" v-model="inputName">
      </div>
      <div class="form-group">
        <label for="exampleInputPhone">電話*</label>
        <input type="text" class="form-control" id="exampleInputPhone" placeholder="Phone" v-model="inputPhone">
      </div>
      <div class="form-group">
        <label for="exampleInputEmail">Email*</label>
        <input type="email" class="form-control" id="exampleInputEmail" placeholder="Email" v-model="inputEmail">
      </div>
      <div class="form-group card-number-group">
        <label for="card-number" class="control-label"><span id="cardtype"></span>卡號</label>
        <div class="form-control card-number"></div>
      </div>
      <div class="form-group expiration-date-group">
        <label for="expiration-date" class="control-label">卡片到期日</label>
        <div class="form-control expiration-date" id="tappay-expiration-date"></div>
      </div>
      <div class="form-group ccv-group">
        <label for="ccv" class="control-label">卡片檢查碼</label>
        <div class="form-control ccv" ref="ccv"></div>
      </div>

      <label>發票資訊</label>
      <div class="form-group">
        <label for="exampleInvoiceEmail">Email*</label>
        <input type="email" class="form-control" id="exampleInvoiceEmail" placeholder="Name" v-model="invoiceEmail">
      </div>
      <div class="form-group">
        <label for="exampleInvoiceCode">手機載具</label>
        <input type="text" class="form-control" id="exampleInvoiceCode" placeholder="" v-model="invoiceCode">
      </div>
      <div class="form-group">
        <label for="exampleInvoiceAddress">帳單地址</label>
        <input type="text" class="form-control" id="exampleInvoiceAddress" placeholder="" v-model="invoiceAddress">
      </div>

      <button type="submit" class="btn btn-default" :disabled="!isSubmitAvailable">確認結帳</button>
    </form>
  </div>
</template>

<script>
// import axios from 'axios';
import { mapActions } from 'vuex';

export default {
  name: 'TappayComponent',
  data() {
    return {
      inputName: '',
      inputEmail: '',
      inputPhone: '',

      invoiceEmail: '',
      invoiceCode: '',
      invoiceAddress: '',

      cardAvailable: false,
    };
  },
  props: {
    shopItemId: {
      type: Number,
      required: true,
    }
  },
  components: {

  },
  mounted() {
    this.inputName = this.$store.state.user.displayName;
    this.inputPhone = this.$store.state.user.phone;
    this.inputEmail = this.$store.state.user.email;
    this.invoiceEmail = this.$store.state.user.email;
    // setTimeout(() => {
    //   this.initTappay();
    // }, 3000);
    this.initTappay();
  },
  watch: {
  },
  computed: {
    isSubmitAvailable() {
      if (this.inputName.trim().length === 0) {
        return false;
      }
      if (this.inputPhone.trim().length === 0) {
        return false;
      }
      if (this.inputEmail.trim().length === 0) {
        return false;
      }
      if (this.invoiceEmail.trim().length === 0) {
        return false;
      }
      return this.cardAvailable;
    },
  },
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    initTappay() {
      window.TPDirect.setupSDK(127996, 'app_6tXMnN7yChP3bM6rqeIWvStjsSdohk2BWXGAOuqYrYzXkN3mKSLct78m7Gf6', 'production');
      window.TPDirect.card.setup({
        fields: {
          number: {
            element: '.form-control.card-number',
            placeholder: '**** **** **** ****'
          },
          expirationDate: {
            element: document.getElementById('tappay-expiration-date'),
            placeholder: 'MM / YY'
          },
          ccv: {
            element: this.$refs.ccv,
            placeholder: '後三碼'
          }
        },
        styles: {
          'input': {
            'color': 'gray'
          },
          'input.ccv': {
            // 'font-size': '16px'
          },
          ':focus': {
            'color': 'black'
          },
          '.valid': {
            'color': 'green'
          },
          '.invalid': {
            'color': 'red'
          },
          '@media screen and (max-width: 400px)': {
            'input': {
              'color': 'orange'
            }
          }
        },
        // 此設定會顯示卡號輸入正確後，會顯示前六後四碼信用卡卡號
        isMaskCreditCardNumber: true,
        maskCreditCardNumberRange: {
          beginIndex: 6, 
          endIndex: 11
        }
      });
      window.TPDirect.card.onUpdate((update) => {
        if (update.canGetPrime) {
          this.cardAvailable = true;
        } else {
          this.cardAvailable = false;
        }
      });
    },
    async formSend() {
      const tappayBusyName = 'TAPPAYBUSY';
      try {
        this.forceBlurIos()
      
        console.log(tappayBusyName);
        this.appendComponentBusy(tappayBusyName);
        const tappayStatus = window.TPDirect.card.getTappayFieldsStatus()
        // console.log(tappayStatus)

        // Check TPDirect.card.getTappayFieldsStatus().canGetPrime before TPDirect.card.getPrime
        if (tappayStatus.canGetPrime === false) {
          alert('can not get prime')
          return
        }

        const prime = await this.getPrimePromise();
        // console.log(prime);

        const bill = await this.$store.dispatch('api/createShopBillPromise', {
          shopItemSerial: this.shopItemId,
          cardName: this.inputName,
          cardEmail: this.inputEmail,
          cardPhone: this.inputPhone,
          invoiceEmail: this.invoiceEmail,
          invoiceCode: this.invoiceCode,
          invoiceAddress: this.invoiceAddress,
        });
        // console.log(bill);

        const billInfo = await this.$store.dispatch('api/payBillPromise', {prime, billId: bill.serial});
        // console.log(billInfo);

        this.clearComponentBusy(tappayBusyName);

        window.location.href = billInfo.payment_url;
      } catch(e) {
        this.appendErrorMsg(e.toString());
      } finally {
        this.clearComponentBusy(tappayBusyName);
      }
      
      // window.TPDirect.card.getPrime((result) => {
      //   console.log(result);
      //   if (result.status !== 0) {
      //     alert('get prime error ' + result.msg)
      //     return
      //   }

      //   // alert('get prime 成功，prime: ' + result.card.prime)
      //   axios.post('/closet/api/tappay/pay', {
      //     prime: result.card.prime,
      //   }).then(d => {
      //     console.log(d);
      //     if (d.status === 200) {
      //       const url = d.data.data.payment_url;
      //       window.location.href = url;
      //     }
      //   })
      // });

      // payBillPromise
      
    },
    getPrimePromise() {
      return new Promise((resolve, reject) => {
        window.TPDirect.card.getPrime((result) => {
          if (result.status !== 0) {
            alert('get prime error ' + result.msg);
            reject(result.msg);
          } else {
            resolve(result.card.prime);
          }
        });
      });
    },
    forceBlurIos() {
      if (!this.isIos()) {
          return
      }
      var input = document.createElement('input')
      input.setAttribute('type', 'text')
      // Insert to active element to ensure scroll lands somewhere relevant
      document.activeElement.prepend(input)
      input.focus()
      input.parentNode.removeChild(input)
    },
    isIos() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    }
  }
}
</script>

<style scoped>
  label {
    font-weight: 1000;
  }

  button {
    background-color: #444;
    border-radius: .5rem;
    color: #fff;
    width: 100%;
  }
</style>
