<template>
  <FullScreenModal title="服務詳情" @back="backClicked">
    <div class="delivery-shop-item">

      <div v-if="status === 0" class="pt-3">
        <div class="container">
          <h2 class="font-weight-bold"><img class="logo-img" :src="require(`@/assets/logos/walkingcloset.png`)"/>長腳衣櫥</h2>
          <div class="flow-desc">
            <div class="flow-intro">
              <h3>服務內容：</h3>
            </div>

            <div class="flow-intro">
              <p>
                共享衣櫥：夏日換衣派對<br/>
                9/16-17 換衣資格申請（限購買1週內啟用）<br/>
                可於活動兩日帶著符合資格的衣物至華山文創園區 ACT NOW 永續行動嘉年華 - 長腳衣櫥攤位換衣
              </p>
            </div>

            <div class="flow-intro">
              <h3>服務加購時間：</h3>
            </div>

            <div class="flow-intro">
              <p>即日起至2023.09.23</p>
            </div>

            <div class="flow-intro">
              <h3>收件數量：</h3>
            </div>

            <div class="flow-intro">
              <p>每單上限2件</p>
            </div>

            <div class="flow-intro">
              <h3>服務費用：</h3>
            </div>

            <div class="flow-intro">
              <p>NTD 100 / 次（2件）</p>
            </div>

            <div class="flow-intro">
              <h3>退款資訊：</h3>
            </div>

            <div class="flow-intro">
              <ul>
                <li>完成付款後，未送出衣物資料前，可於7天內申請退費</li>
                <li>付款完成後須於7天內送出衣物資料，超過時間視同放棄本次服務。</li>
              </ul>
            </div>

            <div class="flow-intro">
              <h3>審核標準：</h3>
            </div>

            <div class="flow-intro">
              <ul>
                <li>不限季節</li>
                <li>全新或狀況良好的女裝外套、女裝上衣、連身裙、包包</li>
                <li>衣物 9 成新以上，並為品牌衣物。或衣物狀態良好，原價值新台幣 1,000 元以上</li>
                <li>品牌女裝需可查詢品牌來源，如為無品牌服飾，則無法通過審核</li>
                <li>衣物的內領品牌標籤、尺寸標籤及洗標完整</li>
                <li>尺寸介於 S、M、L、XL</li>
              </ul>
            </div>

          </div>
        </div>

        <div class="bottom-buy">
          <div class="buy-title">
            9/16-17 換衣資格申請
          </div>
          <div class="buy-price">
            NTD 100
          </div>
          <a href="#" class="buy-link" @click.prevent="buyClicked">
            購買
          </a>
        </div>

      </div>
      
      <div v-if="status === 1" class="h-100" style="min-height: 100%;">
        <div class="shop-snippet">
          <div class="logo">
            <img :src="require(`@/assets/logos/walkingcloset.png`)" />
          </div>

          <div class="info">
            <p>9/16-17 換衣資格申請</p>
            <p>NTD 100</p>
          </div>
        </div>

        <div class="pay-breadcrumb">
          <span>訂單資訊</span>
          <span class="px-2">
            <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 9L5 5L1 1" stroke="#4D4D4D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
          <span>付款</span>
        </div>

        <div class="p-3" v-if="payStatus === 0">
          <p class="font-weight-bold">訂購人</p>

          <div class="row no-gutters mb-3">
            <div class="col-auto px-3">
              <div class="info-img">
                <img :src="user.picUrl"/>
              </div>
            </div>
            <div class="col">
              <p>{{ user.displayName }}</p>
              <p>{{ user.email }}</p>

            </div>
          </div>

          <div class="row no-gutters mb-3">
            <div class="col-auto pr-2">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.9974 3.01198L11.8038 3.89091L11.9974 3.01198C11.5709 2.91805 11.2402 3.10958 11.0639 3.26326C10.9043 3.40238 10.7926 3.57232 10.7202 3.69291C10.5768 3.93174 10.4194 4.27178 10.2504 4.63699C10.2434 4.65193 10.2365 4.66692 10.2295 4.68195L7.49821 10.5816C7.48913 10.6012 7.48008 10.6208 7.47105 10.6403C7.19853 11.2288 6.95285 11.7593 6.82276 12.2011C6.68401 12.6724 6.60759 13.2655 6.96389 13.8231C7.3202 14.3807 7.89054 14.5605 8.37646 14.6326C8.83208 14.7001 9.41673 14.7001 10.0652 14.7C10.0867 14.7 10.1083 14.7 10.1299 14.7H11.1V18.7299L11.1 18.7794C11.0999 19.1818 11.0999 19.5565 11.1297 19.8335C11.1447 19.9734 11.1747 20.1745 11.2611 20.3678C11.3565 20.5814 11.5762 20.8941 12.0026 20.988C12.4291 21.0819 12.7598 20.8904 12.9361 20.7367C13.0957 20.5976 13.2074 20.4277 13.2798 20.3071C13.4232 20.0683 13.5806 19.7282 13.7496 19.363L13.7705 19.318L16.5018 13.4184C16.5109 13.3987 16.5199 13.3792 16.5289 13.3597C16.8015 12.7712 17.0472 12.2407 17.1772 11.7989C17.316 11.3276 17.3924 10.7345 17.0361 10.1769L16.2777 10.6615L17.0361 10.1769C16.6798 9.61934 16.1095 9.4395 15.6235 9.36743C15.1679 9.29985 14.5833 9.29992 13.9348 9.29999C13.9133 9.3 13.8917 9.3 13.8701 9.3H12.9V5.27013C12.9 5.25357 12.9 5.23706 12.9 5.22059C12.9001 4.81816 12.9001 4.44347 12.8703 4.16649C12.8553 4.02663 12.8253 3.82549 12.7389 3.63218C12.6435 3.41865 12.4238 3.10591 11.9974 3.01198Z" stroke="#E3861A" stroke-width="1.8"/>
              </svg>
            </div>
            <div class="col">
              <p>
                請留意本服務為線上服務，完成購買後可立即使用。發票將以電子發票方式寄至您的信箱。<br/>
                <br/>
                如遇問題可前往本站「聯絡我們」頁面提交表單，或以使用以下方式聯繫我們：<br/>
                <br/>
                客服信箱：service@walkingcloset.co<br/>
                客服LINE： ＠walkingcloset<br/>
                客服專線：02-7752-2510
              </p>
            </div>
          </div>

          <div>
            <button class="btn btn-dark w-100 d-block py-3" @click="payStatus = 1">下一步</button>
          </div>
        </div>
        
        <div v-if="payStatus === 1">
          <div class="p-3">
            <p class="font-weight-bold">付款</p>
            <p class="text-secondary">本公司採用喬睿科技 TapPay SSL 2048 bit 交易系統，消費者刷卡時直接在銀行端系統中交易，本公司決不留下您的信用卡資料，以保障您的權益。 TapPay 交易系統通過 PCI-DSS 國際信用卡組織安全稽核，周全保護您的信用卡資料安全。如信用卡交易異常，可選取 LINE Pay 方式進行付款。</p>
          </div>

          <div class="p-3">
            <TappayComponent :shopItemId="id"></TappayComponent>

            <div class="pt-4">
              <p>
                <img :src="require('@/assets/images/tappay.png')" />
              </p>
              <p style="color: #aaa;">
                本服務由喬睿科技 TapPay 提供。交易全程使用 TLS 1.2 加密，請放心支付。
              </p>
            </div>
          </div>
        </div>
        
        <div class="pay-footer">
          <a href="#" @click.prevent="policyModalShow1 = true">購物流程</a>
          <a href="#" @click.prevent="policyModalShow3 = true">退換貨政策</a>
          <a href="#" @click.prevent="policyModalShow2 = true">隱私權聲明</a>
        </div>
      </div>

      <div v-if="status === 2" class="h-100" style="min-height: 100%;">
        <div class="buy-done">
          <h3>服務加購完成</h3>
          <p>請至服務紀錄，送出衣物資料</p>

          <button class="btn btn-lg btn-outline-primary" @click="buyFinish">確認</button>
        </div>
      </div>

      <transition name="fade">
        <WcCommonModal title="確定離開" v-if="isConfirmModalShow" @cancel="isConfirmModalShow = false" @ok="buyConfirmed" hide-header>
          <div class="text-center">
            確定購買嗎？
          </div>
        </WcCommonModal>
      </transition>

      <transition name="fade">
        <WcCommonModal title="購物流程" v-if="policyModalShow1" @close="policyModalShow1 = false" hide-ok hide-cancel>
          <div class="">
            <p>
              一、購物流程<br/>
              <br/>
              單次媒合服務流程如下：<br/>
              選擇服務並完成加購<br/>
              依據需求送出衣物資料<br/>
              等待服務提供夥伴報價<br/>
              確認報價後，依需求寄出衣物<br/>
              等待收件確認<br/>
              <br/>
              二、付款方式<br/>
              <br/>
              僅提供線上刷卡（VISA、MASTER、JCB 皆可使用）及 LINE pay。<br/>
              <br/>
              三、商品/服務性質<br/>
              <br/>
              本網站販售之商品/服務皆為非以有形媒介提供之數位內容或一經提供即為完成之線上服務，為特殊性質商品。<br/>
              <br/>
              四、發票開立<br/>
              <br/>
              您可以選擇以下三擇一方式取得發票：<br/>
              電子發票（雲端發票）：系統會在您付款後的7個工作天內開立發票，並寄送至您的 email 信箱（開獎後會自動為您兌獎，如您中獎，會將發票印出、並以掛號寄送給您）<br/>
              捐贈：若選擇捐贈，系統會自動將您的發票捐至合作的非營利組織（協會或基金會）<br/>
              公司戶紙本發票：根據財政部令「電子發票實施作業要點」，於本網站消費皆開立電子發票，若您有開立統編報帳的需求，請於結帳時輸入統一編號和發票抬頭，我們將會寄出您的公司戶電子發票至您的 email 信箱。
            </p>
          </div>
        </WcCommonModal>
      </transition>

      <transition name="fade">
        <WcCommonModal title="確認購買" v-if="noClothModalShow" @close="noClothModalShow = false" hide-header @ok="noClothOkClicked" @backClick="noClothModalShow = false" @cancel="noClothCancelClicked">
          <div class="">
            <p class="text-center">
              確認購買<br/>
              <br/>
              本服務會需要上傳至少一件衣物資訊。<br/>
              <br/>
              確認購買前建議先試著上傳一件衣物了解上傳流程喔！
            </p>
          </div>
          <span slot="cancel">試試上傳衣物</span>
          <span slot="ok">直接購買</span>
        </WcCommonModal>
      </transition>

      <transition name="fade">
        <WcCommonModal title="隱私權聲明" v-if="policyModalShow2" @close="policyModalShow2 = false" hide-ok hide-cancel>
          <div class="">
            <p>
              非常歡迎您光臨長腳衣櫥線上服務（以下簡稱本網站），為了讓您能夠安心使用本網站的各項服務與資訊，特此向您說明本網站的隱私權聲明將如何保護照訪本網頁用戶之相關資訊。使用本網站的同時，即表示您同意長腳衣櫥依此隱私權聲明所述之方式使用您的資訊。<br/>
              <br/>
              如關於本隱私權聲明有任何問題，或有未盡事宜，請與我們聯繫。<br/>
              <br/>
              一、隱私權保護聲明的適用範圍<br/>
              <br/>
              隱私權保護聲明內容，包括本網站如何處理在您使用網站服務時收集到的個人識別資料。隱私權保護聲明不適用於本網站以外的相關連結網站，也不適用於非本網站所委託或參與管理的人員。<br/>
              <br/>
              二、個人資料的蒐集、處理及利用方式<br/>
              <br/>
              當您造訪本網站或使用本網站所提供之功能服務時，我們將視該服務功能性質，請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料；非經您同意，本網站不會將個人資料用於其他用途。<br/>
              <br/>
              本網站在您使用服務信箱、問卷調查等互動性功能時，會保留您所提供的姓名、電子郵件地址、聯絡方式及使用時間等。<br/>
              <br/>
              於一般瀏覽時，伺服器會自行記錄相關行徑，包括您使用連線設備的IP位址、使用時間、使用的瀏覽器、瀏覽及點選資料記錄等，做為我們增進網站服務的參考依據，此記錄為內部應用，決不對外公佈。<br/>
              <br/>
              為提供精確的服務，我們會將收集的問卷調查內容進行統計與分析，分析結果之統計數據或說明文字呈現，除供內部研究外，我們會視需要公佈統計數據及說明文字，但不涉及特定個人之資料。<br/>
              <br/>
              您可以隨時向我們提出請求，以更正或刪除您的帳戶或本網站所蒐集的個人資料等隱私資訊。聯繫方式請見最下方聯繫管道。<br/>
              <br/>
              三、資料之保護<br/>
              <br/>
              本網站主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以保護網站及您的個人資料採用嚴格的保護措施，只由經過授權的人員才能接觸您的個人資料，相關處理人員皆簽有保密合約，如有違反保密義務者，將會受到相關的法律處分。<br/>
              <br/>
              如因業務需要有必要委託其他單位提供服務時，本網站亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。<br/>
              <br/>
              四、網站對外的相關連結<br/>
              <br/>
              本網站的網頁提供其他網站的網路連結，您也可經由本網站所提供的連結，點選進入其他網站。但該連結網站不適用本網站的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。<br/>
              <br/>
              五、與第三人共用個人資料之政策<br/>
              <br/>
              為提供您完整的服務及更感興趣的資訊、市場調查等研究，本網站將提供您的個人資料予長腳衣櫥合作夥伴或委託處理相關資訊之第三方，並會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。前述之揭露行為可能將您的個人資料傳輸至尚未制訂相關資料保護法令之國家或地區。<br/>
              <br/>
              本網站將依法提供您的個人資料予執法單位、主管機關或其他政府機構，或其他依法規命令有義務蒐集您個人資料之其他第三方。<br/>
              <br/>
              如果您通過本網站提供個人資料，即表示您同意上述之揭露行為。<br/>
              <br/>
              六、Cookie之使用<br/>
              <br/>
              為了提供您最佳的服務，本網站會在您的電腦中放置並取用我們的Cookie，若您不願接受Cookie的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕Cookie的寫入，但可能會導至網站某些功能無法正常執行 。<br/>
              <br/>
              七、隱私權保護政策之修正<br/>
              <br/>
              本網站隱私權保護政策將因應需求隨時進行修正，修正後的條款將刊登於網站上。<br/>
              <br/>
              八、聯繫管道<br/>
              <br/>
              對於本站之隱私權政策有任何疑問，或者想提出變更、移除個人資料之請求，請前往本站「聯絡我們」頁面提交表單。或者 Email 至：hi@walkingcloset.co
            </p>
          </div>
        </WcCommonModal>
      </transition>

      <transition name="fade">
        <WcCommonModal title="退換貨政策" v-if="policyModalShow3" @close="policyModalShow3 = false" hide-ok hide-cancel>
          <div class="">
            <p>
              非常歡迎您光臨長腳衣櫥線上服務（以下簡稱本網站），為了提供您便利又安心的交易環境，特此向您說明本網站的退換貨政策。為了保障您的權益，請務必詳讀本條款，在您註冊成為本網站會員、或開始使用本服務時，即視為您已經閱讀、瞭解並同意接受本條款及「隱私聲明」之所有內容，並視為您已確認詳閱並同意個人資料保護告知暨同意事項。<br/>
              <br/>
              如有任何問題，請與我們聯繫。<br/>
              <br/>
              一、退款期限<br/>
              <br/>
              本網站販售之商品/服務皆為非以有形媒介提供之數位內容或一經提供即為完成之線上服務，為特殊性質商品，依消費者保護法及通訊交易解除權合理例外情事適用準則之規定，不適用七天猶豫期之權益。<br/>
              <br/>
              本網站提供優於規定的退款期限，若服務尚未使用，可於七天內退款。若服務已使用則無法退款。<br/>
              <br/>
              以下為服務已使用狀態：<br/>
              使用單次服務：已暫存/送出資訊<br/>
              使用訂閱制服務：已輸入個人化資訊或任一個人化設定。<br/>
              <br/>
              二、退款流程<br/>
              <br/>
              請以Email或官方LINE訊息聯繫長腳衣櫥客服人員，待確認可退款後，由客服人員協助完成退款申請。<br/>
              若已取得電子發票，將會於我方完成退款後，將電子發票作廢。<br/>
              <br/>
              三、退款金流說明<br/>
              <br/>
              完成退款申請後，本公司將於7-14個工作日內（不含週休及國定例假日），將該筆金額退刷至您的交易信用卡，完成刷退後將以電子郵件通知。<br/>
              <br/>
              由於各銀行入帳時間、作業程序之不同，可能需 15-20 天入帳，刷退款項可能列於本月或次月的帳單上，或直接與發卡銀行確認。<br/>
              <br/>
              四、聯繫管道<br/>
              <br/>
              如需退款，可前往本站「聯絡我們」頁面提交表單。或使用以下方式<br/>
              客服信箱：service@walkingcloset.co<br/>
              客服LINE：@walkingcloset<br/>
              客服專線：02-7752-2510<br/>
              聯絡地址：台北市中山區林森北路575號7樓之9
            </p>
          </div>
        </WcCommonModal>
      </transition>
    </div>
  </FullScreenModal>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import FullScreenModal from '@/components/FullScreenModal.vue';
import WcCommonModal from '@/components/commons/WcCommonModal.vue';
import TappayComponent from '@/components/TappayComponent.vue';

import '@/assets/css/buttons.css';

export default {
  name: 'DeliveryShopItemDialog',
  data() {
    return {
      initBusyName: 'DELIVERYSHOPITEMINIT',
      saveBusyName: 'DELIVERYSHOPITEMSAVE',
      init: false,

      status: 0,
      payStatus: 0,

      noClothModalShow: false,
      isConfirmModalShow: false,
      policyModalShow1: false,
      policyModalShow2: false,
      policyModalShow3: false,
    };
  },
  components: {
    FullScreenModal,
    WcCommonModal,
    TappayComponent,
  },
  props: {
    id: {
      type: Number,
      required: false,
      default: -1,
    }
  },
  created() {
  },
  beforeDestroy() {
    this.clearComponentBusy(this.initBusyName);
    this.clearComponentBusy(this.saveBusyName);
  },
  async mounted() {
    this.appendComponentBusy(this.initBusyName);
    try {
      this.init = true;
    } catch(e) {
      this.emitError(e);
    } finally {
      this.clearComponentBusy(this.initBusyName);
    }
  },
  computed: {
    ...mapState(['cloths']),
    user() {
      return this.$store.state.user;
    }
  },
  watch: {
  },
  methods: {
    ...mapActions(['appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    buyClicked() {
      if (this.cloths.length >= 5) {
        this.noClothOkClicked();
      } else {
        this.noClothModalShow = true;
      }
    },
    noClothOkClicked() {
      this.status = 1;
      this.noClothModalShow = false;
    },
    noClothCancelClicked() {
      this.$emit('back');
      this.$router.push({name: 'ClosetContentListAll'});
    },
    async buyConfirmed() {
      // this.appendComponentBusy(this.saveBusyName);
      // try {
      //   await this.$store.dispatch('api/buyDeliveryShopItemFakePromise', this.id);
      //   this.status = 1;
      //   this.isConfirmModalShow = false;
      // } catch(e) {
      //   this.emitError(e);
      // } finally {
      //   this.clearComponentBusy(this.saveBusyName);
      // }

      this.status = 1;
      this.isConfirmModalShow = false;
    },

    backClicked() {
      this.$emit('back');
    },
    buyFinish() {
      this.$emit('buy');
    },
  }
}
</script>

<style scoped>
  .delivery-shop-item {
    height: 100%;
    min-height: 100%;
  }

  h2 {
    font-size: 1.2rem;
  }

  .logo-img {
    width: 1.4em;
    height: 1.4em;
    display: inline-block;
    margin-right: .5em;
  }

  .flow-desc {
  }

  .flow-desc .flow-intro {
    background-color: #F8F8F8;
    padding: .8rem 1.2rem;
    color: #8d8d8d;
  }

  .flow-desc .flow-intro:nth-child(2n) {
    background-color: #FFF;
  }

  .flow-desc .flow-intro>h3 {
    font-size: 1.2rem;
    font-weight: 1000;
    margin: 0;
    color: #4D4D4D;
  }

  .flow-desc .flow-intro>p {
    margin: 0;
  }

  .bottom-buy {
    position: sticky;
    left: 0;
    bottom: 0;
    background-color: var(--main-yellow);
    display: flex;
    align-items: center;
    padding: 1rem;
  }

  .buy-title {
    color: #4D4D4D;
    flex: 1 1;
  }

  .buy-price {
    color: #4D4D4D;
    flex: 0 0 auto;
  }

  .buy-link {
    padding-left: 1rem;
    color: #fff;
  }
  
  .buy-link:hover, .buy-link:active {
    text-decoration: none;
  }

  .buy-done {
    min-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .shop-snippet {
    padding: 1rem 1.2rem;
    display: flex;
    align-items: center;
    background-color: #F9F9F9;
    border-bottom: solid 1px #C4C4C4; 
  }

  .shop-snippet .logo {
    flex: 0 0 40px;
    padding-right: 8px;
  }

  .shop-snippet .logo>img {
    width: 32px;
    height: 32px;
    object-fit: contain;
  }

  .shop-snippet .info {
    flex: 1 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--main-yellow);
  }

  .shop-snippet .info>p {
    margin-bottom: 0;
  }

  .pay-breadcrumb {
    padding: 1rem 1.2rem;
    display: flex;
    align-items: center;
  }

  .info-img {
    width: 2rem;
    height: 2rem;
    overflow: hidden;
    border-radius: 50%;
  }

  .info-img>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .pay-footer {
    position: sticky;
    bottom: 0;
    left: 0;
    padding: .8rem 2.5rem;
    border-top: solid 1px #C4C4C4;
    display: flex;
    justify-content: space-between;
    background-color: #FFF;
  }

  .pay-footer a {
    color: #444;
    text-decoration: underline;
  }
</style>
