<template>
  <div class="delivery-shop">
    <div class="container">
      <div class="flow-desc">
        <div class="flow-intro">
          <h2>流程</h2>
        </div>

        <div class="flow-intro">
          <span>1</span>
          <p>選擇服務並完成加購。</p>
        </div>

        <div class="flow-intro">
          <span>2</span>
          <p>依據需求送出衣物資料。</p>
        </div>

        <div class="flow-intro">
          <span>3</span>
          <p>等待服務提供夥伴審核。</p>
        </div>

        <div class="flow-intro">
          <span>4</span>
          <p>通過審核後，依據服務需求進行後續動作。</p>
        </div>

        <!-- <div class="flow-intro">
          <span>5</span>
          <p>等待收件確認。</p>
        </div> -->
      </div>
    </div>
    
    <div>

      <div class="shop-list">
        <div class="container shop-item" v-for="(item) in shopItems" :key="item.serial">
          <div class="shop-item-img">
            <span class="img-container">
              <img :src="require(`@/assets/logos/walkingcloset.png`)"/>
            </span>
          </div>

          <div class="shop-item-desc">
            <div class="shop-item-desc-text">
              <p v-html="item.desc"></p>
              <label>[{{ item.businessAccount.name }}]{{ item.name }}</label>
            </div>

            <div class="shop-item-desc-bottom">
              <div class="desc">
                <p>
                  <small>$</small>{{ item.price }}
                </p>
                <span>即日起至 2023.09.23</span>
              </div>
              <div class="bottom-btn">
                <button :disabled="item.billAmount >= item.limit" @click.prevent="shopItemClicked(item)">
                  <span v-if="(item.billAmount < item.limit)">
                    申請
                  </span>
                  <span v-if="(item.billAmount >= item.limit)">
                    售完
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- <a class="shop-item" href="#" @click.prevent="openShopItemModal(1)">
          <div class="shop-item-img">
            <span class="img-container">
              <img :src="require(`@/assets/logos/80percent.jpg`)"/>
            </span>
          </div>

          <div class="shop-item-desc">
            <p>
              奧斯丁小舖<br/>
              這邊是測試用的~~~<br/>
              NTD 149
            </p>
            <p class="link-more">
              服務詳情 &gt;
            </p>
          </div>
        </a> -->

      </div>
    </div>

    <transition name="fade">
      <DeliveryShopItemDialog :id="selectedShopItemId" v-if="isShopItemShow" @back="closeShopItemModal" @buy="boughtShopItem"></DeliveryShopItemDialog>
    </transition>
  </div>
</template>


<script>
import { mapActions } from 'vuex';
import DeliveryShopItemDialog from './DeliveryShopItemDialog.vue';

export default {
  name: 'DeliveryShop',
  data() {
    return {
      readingBusyName: 'READINGSNIPPESTDATA',
      selectedShopItemId: -1,
      isShopItemShow: false,

      shopItems: [],
    };
  },
  components: {
    DeliveryShopItemDialog,
  },
  props: {
  },
  beforeDestroy() {
    this.clearComponentBusy(this.readingBusyName);
  },
  async mounted() {
    await this.readShopList();
    
  },
  computed: {
  },
  watch: {
  },
  methods: {
    ...mapActions(['showMsgModal', 'setBodyConfigShow', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg']),
    openShopItemModal(id) {
      this.selectedShopItemId = id;
      this.isShopItemShow = true;
    },
    closeShopItemModal() {
      this.isShopItemShow = false;
    },
    boughtShopItem() {
      this.isShopItemShow = false;
      this.$router.push({name: 'DeliveryItemList'});
    },
    async readShopList() {
      this.appendComponentBusy(this.readingBusyName);
      try {
        this.shopItems.splice(0, this.shopItems.length);
        const list = await this.$store.dispatch('api/readShopItemListPromise');
        for (const l of list) {
          const { count } = await this.$store.dispatch('api/getPaidBillAmountPromise', l.serial);
          l.billAmount = count;
          this.shopItems.push(l);
        }
      } catch (error) {
        this.showMsgModal('無法取得商品清單');
      } finally {
        this.clearComponentBusy(this.readingBusyName);
      }
    },
    async shopItemClicked(item) {
      if (item.billAmount >= item.limit) {
        return;
      }
      const serial = item.serial;
      this.appendComponentBusy(this.readingBusyName);
      try {
        const shopItem = await this.$store.dispatch('api/readShopItemInfoPromise', serial);
        const { count } = await this.$store.dispatch('api/getPaidBillAmountPromise', serial);
        if (count >= shopItem.limit) {
          this.showMsgModal('此商品已售完');
          return;
        } else {
          this.openShopItemModal(serial);
        }
      } catch (error) {
        this.showMsgModal('無法取得商品已售出數量');
      } finally {
        this.clearComponentBusy(this.readingBusyName);
      }
      
    }
  }
}
</script>

<style scoped>
  .delivery-shop {
    padding: .5rem 0;
  }

  .flow-desc {
    color: #4D4D4D;
  }

  .flow-desc .flow-intro {
    background-color: #F8F8F8;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: .8rem 1.2rem;
  }

  .flow-desc .flow-intro:nth-child(2n) {
    background-color: #FFF;
  }

  .flow-desc .flow-intro>h2 {
    font-size: 1.2rem;
    font-weight: 1000;
    margin: 0;
  }

  .flow-desc .flow-intro>span {
    background-color: var(--main-green);
    color: #fff;
    display: flex;
    width: 1.6em;
    height: 1.6em;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }

  .flow-desc .flow-intro>p {
    margin: 0;
    padding-left: 1em;
  }

  .shop-list {

  }

  .shop-item {
    display: flex;
    width: 100%;
    padding-top: .5rem;
    padding-bottom: .5rem;
    color: #4D4D4D;
    align-items: stretch;
    border-top: solid 1px #c4c4c4;
  }
  .shop-item:last-child {
    border-bottom: solid 1px #c4c4c4;
  }

  .shop-item:focus, .shop-item:active {
    text-decoration: none;
  }

  .shop-item-img {
    position: relative;
    flex: 0 0 25%;
  }

  .fake-img {
    display: block;
    width: 100%;
    padding-top: 100%;
    border-radius: 1rem;
    background-color: #999;
  }

  .img-container {
    position: relative;
    display: block;
    width: 100%;
    padding-top: 100%;
    border-radius: 1rem;
    overflow: hidden;
    border-radius: 12px;
    border: solid 1px #D9D9D9;
  }

  .img-container>img {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .shop-item-desc {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: .5rem;
  }

  .shop-item-desc>p {
    line-height: 1.2em;
    margin: 0;
  }
  
  .shop-item-desc-text {

  }

  .shop-item-desc-text>p {
    /* font-size: .75rem; */
    margin-bottom: 0;
  }

  .shop-item-desc-text>label {
    /* font-size: .8rem; */
    font-weight: 1000;
    margin-bottom: 0;
  }

  .shop-item-desc-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .shop-item-desc-bottom .desc {

  }

  .shop-item-desc-bottom .desc>p {
    margin-bottom: 0;
    /* font-size: .8rem; */
  }

  .shop-item-desc-bottom .desc>span {
    /* font-size: .75rem; */
  }

  .bottom-btn {

  }

  .bottom-btn button {
    display: block;
    background-color: var(--main-orange);
    border: none;
    border-radius: 999px;
    padding: .5rem 1.5rem;
    color: #fff;
    font-weight: 1000;
  }

  .bottom-btn button:disabled {
    opacity: .6;
  }

  .link-more {
    color: #8d8d8d;
    text-align: right;
  }
  
  .shop-item-desc p.shop-item-desc-text {
    line-height: 1.8em;
  }
</style>
